export default class Intro {
  constructor() {
    this.init();
  }

  init() {

    $(window).on('load', () => {
      this.animation();
    });
  }

  animation() {

    const tl = new TimelineMax({});
    tl.set('.sidebnr', { x: 56 });

    tl.to('.loader', 1, { opacity: 0, display: 'none' }, 0);

    tl.to('.-curtain01', 0.4, { width: '0%', ease: Circ.easeInOut }, 0.8);
    tl.to('.-curtain02', 0.4, { width: '0%', ease: Circ.easeInOut }, 1.2);
    tl.to('.-curtain03', 0.4, { width: '0%', ease: Circ.easeInOut }, 1.2);
    tl.to('.sidebnr', 0.4, { x: 0, ease: Circ.easeInOut }, 1.2);
  }

}
export default class Ui {
    constructor() {
        this.nrwBP = 768;
        this.init();
    }

    init() {
        this.fixedNav();
        this.share_acc();
        this.official_acc();
        this.pageTop();
    }

    fixedNav() {
        var nav = $('.js-fixednav');
        var offset = nav.offset();

        $(window).on('load scroll', function () {
            if ($(window).scrollTop() > offset.top) {
                nav.addClass('-fixed');
            } else {
                nav.removeClass('-fixed');
            }
        });
    }

    share_acc() {
        $('.js-share_acc').on('click', function () {
            $('.l-sidebnr_nrw__sns').toggleClass('-active');
        });
    }

    official_acc() {
        $('.js-official_acc').on('click', function() {
            $('.official__ttl').toggleClass('-active');
            $('.official__list').toggleClass('-active');
        });
    }

    pageTop() {
        $('.js-pagetop').on('click', function () {
            TweenLite.to(window, 1, { scrollTo: { y: 0, autoKill: false, ease: Circ.easeOut } });
        });
    }
}

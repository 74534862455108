export default class Modal {
    constructor(vol) {
        this.vol = vol;
        this.init();
    }

    init() {
        this.modal();
        this.changeModal();
        this.buyModal();
        this.prModal();
    }

    modal() {
        let sc_top = 0;
        let scrollbar_width = window.innerWidth - document.body.scrollWidth;

        $(".js-modal_open").on("click", function(e) {
            e.preventDefault();
            sc_top = $(window).scrollTop();
            $("html,body").addClass("in_modal");
            $("body,html").animate({ scrollTop: sc_top }, 0);
            $(".modal")
                .removeClass("hide")
                .addClass("active");

            // スクロールバーがあるとき
            if (scrollbar_width) {
                // その分padding-rightを追加
                $("html").css("padding-right", scrollbar_width);
            }
        });

        $(".modal").on("click", ".js-modal_close", function() {
            $("html,body").removeClass("in_modal");
            $("body,html").animate({ scrollTop: sc_top }, 0);
            $(".modal")
                .removeClass("active")
                .addClass("hide");
            $(".js-ajaxcont").empty();

            // スクロールバーがあるとき
            if (scrollbar_width) {
                // その分padding-rightを追加
                $("html").css("padding-right", 0);
            }
        });

        //外側をクリックしたときに閉じるように
        $(".modal").on("click", function(event) {
            if (!$(event.target).closest(".c-inner").length) {
                $("html,body").removeClass("in_modal");
                $("body,html").animate({ scrollTop: sc_top }, 0);
                $(".modal")
                    .removeClass("active")
                    .addClass("hide");
                $(".js-ajaxcont").empty();

                // スクロールバーがあるとき
                if (scrollbar_width) {
                    // その分padding-rightを追加
                    $("html").css("padding-right", 0);
                }
            }
        });
    }

    changeModal() {
        let index = 0;
        const indexMax = $(".js-thumbswitch").length - 1;
        const that = this;

        //ajax処理
        function getCont($selector, direction) {
            const file = $selector.attr("data-modal") + ".html";
            const filePath = "/_inc/page/" + that.vol + "/modal/" + file;

            $.ajax({
                type: "GET",
                url: filePath,
                dataType: "html"
            }).done(function(data) {
                $(".js-ajaxcont")
                    .empty()
                    .html(data);
                showCont();
            });
        }

        function showCont() {
            //htmlが挿入されるまでwaitを入れる
            setTimeout(function() {
                $(".js-modal_cont").addClass("active");
            }, 100);
        }

        $(".js-thumbswitch").on("click", function(e) {
            //index = $(this).index(".js-thumbswitch");
            $(this)
                .find(".disc__thumb")
                .addClass("active");
            getCont($(this));
        });
    }

    buyModal() {
        let sc_top = 0;
        let scrollbar_width = window.innerWidth - document.body.scrollWidth;

        $(".js-buy_modal_open").on("click", function(e) {
            e.preventDefault();
            sc_top = $(window).scrollTop();
            $("html,body").addClass("in_modal");
            $("body,html").animate({ scrollTop: sc_top }, 0);
            $(".buy_modal")
                .removeClass("hide")
                .addClass("active");
            // スクロールバーがあるとき
            if (scrollbar_width) {
                // その分padding-rightを追加
                $("html").css("padding-right", scrollbar_width);
            }
        });

        $(".buy_modal").on("click", ".js-buy_modal_close", function() {
            $("html,body").removeClass("in_modal");
            $("body,html").animate({ scrollTop: sc_top }, 0);
            $(".buy_modal")
                .removeClass("active")
                .addClass("hide");
            // スクロールバーがあるとき
            if (scrollbar_width) {
                // その分padding-rightを追加
                $("html").css("padding-right", 0);
            }
        });

        //外側をクリックしたときに閉じるように
        $(".buy_modal").on("click", function(event) {
            if (!$(event.target).closest(".buy_modal__cont").length) {
                $("html,body").removeClass("in_modal");
                $("body,html").animate({ scrollTop: sc_top }, 0);
                $(".buy_modal")
                    .removeClass("active")
                    .addClass("hide");
                // スクロールバーがあるとき
                if (scrollbar_width) {
                    // その分padding-rightを追加
                    $("html").css("padding-right", 0);
                }
            }
        });
    }

    prModal() {
        let sc_top = 0;
        let scrollbar_width = window.innerWidth - document.body.scrollWidth;

        //cookie
        let visit7days = Cookies.get("visit7days");
        let visited = Cookies.get("visited");

        function initModal() {
            //youtube iframeを消去
            $(".pr_modal__youtube").html("");
            //modal内のスライドを初期化
            $(".modal_slide.-buy").removeClass("active");
            $(".modal_slide.-pr").addClass("active");
        }

        $(".js-pr_modal_open").on("click", function(e) {
            e.preventDefault();
            sc_top = $(window).scrollTop();
            $("html,body").addClass("in_modal");
            $("body,html").animate({ scrollTop: sc_top }, 0);
            $(".pr_modal")
                .removeClass("hide")
                .addClass("active");
            // スクロールバーがあるとき
            if (scrollbar_width) {
                // その分padding-rightを追加
                $("html").css("padding-right", scrollbar_width);
            }

            //動画は動的に挿入
            $(".pr_modal__youtube").append('<iframe src="//www.youtube.com/embed/Kwvr5498hxU?rel=0" frameborder="0" allowfullscreen></iframe>');
        });

        //初回起動
        //7日間だけモーダルを出して、4週後にリセット
        // if (visit7days && visited) {
        //     $(".js-pr_modal_open").trigger("click");
        // } else if (!visited) {
        //     $(".js-pr_modal_open").trigger("click");
        //     Cookies.set("visit7days", 1, { expires: 7 });
        //     Cookies.set("visited", 1, { expires: 28 });
        // }

        //外側をクリックしたときに閉じるように
        $(".pr_modal").on("click", function(event) {
            if (!$(event.target).closest(".pr_modal__cont").length) {
                $("html,body").removeClass("in_modal");
                $("body,html").animate({ scrollTop: sc_top }, 0);
                $(".pr_modal")
                    .removeClass("active")
                    .addClass("hide");
                // スクロールバーがあるとき
                if (scrollbar_width) {
                    // その分padding-rightを追加
                    $("html").css("padding-right", 0);
                }
                //modal内を初期化
                initModal();
            }
        });

        $(".pr_modal").on("click", ".js-pr_modal_close", function() {
            $("html,body").removeClass("in_modal");
            $("body,html").animate({ scrollTop: sc_top }, 0);
            $(".pr_modal")
                .removeClass("active")
                .addClass("hide");
            // スクロールバーがあるとき
            if (scrollbar_width) {
                // その分padding-rightを追加
                $("html").css("padding-right", 0);
            }
            //modal内を初期化
            initModal();
        });

        $(".js-modal_slide").on("click", function() {
            $(".modal_slide").removeClass("active");
            $(".modal_slide.-buy").addClass("active");
            //youtube iframeを消去
            $(".pr_modal__youtube").html("");
        });
    }
}

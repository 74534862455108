import Ui from './common/ui.es6';
import Share from './common/share.es6';
import Modal from './top/modal.es6';
import Intro from './top/intro.es6';



///COMMON
const ui = new Ui();
const share = new Share();

///PAGE
const modal = new Modal('3');
const intro = new Intro();